const acountTypes = [
  {
    name: "Referred Personal Account",
    value: "personal",
    iconPath: require("@/assets/icons/account/personal.png"),
  },
  {
    name: "Referred Gold Account",
    value: "gold",
    iconPath: require("@/assets/icons/account/gold.png"),
  },
  {
    name: "Referred Business Account",
    value: "business",
    iconPath: require("@/assets/icons/account/business.png"),
  },
];
export default acountTypes;

import "bootstrap/dist/js/bootstrap.js";
import { numeric, imgLoaded, maxlength } from "@/directives";
import Toast from "vue-toastification";
import { createHead } from "unhead";
import GenericCard from "@/components/Global/GenericCard.vue";
import CountrySelect from "@/components/Global/CountrySelect.vue";
import FileUpload from "@/components/Global/FileUpload.vue";
import Spinner from "@/components/Global/Spinner.vue";
import DashboardTabs from "@/components/Global/DashboardTabs.vue";
import EmptyState from "@/components/Global/EmptyState.vue";
import ErrorState from "@/components/Global/ErrorState.vue";
import HeaderSummary from "@/components/Global/HeaderSummary.vue";
import Checkbox from "@/components/Global/Checkbox.vue";
import ModalWrapper from "@/components/Global/ModalWrapper.vue";
import DobPicker from "@/components/Global/DobPicker.vue";
import GenderPicker from "@/components/Global/GenderPicker.vue";
import ActionConfirmation from "@/components/Global/ActionConfirmation.vue";
import PermissionControl from "@/components/Global/PermissionControl.vue";
import DatePeriods from "@/components/Global/DatePeriods.vue";
import CustomDatePeriod from "@/components/Global/CustomDatePeriod.vue";
import DropdownMenu from "@/components/Global/DropdownMenu.vue";
import Pagination from "@/components/Global/Pagination.vue";
import DashboardHeader from "@/components/Dashboard/Menus/Header/Index.vue";
import VOtpInput from "vue3-otp-input";
import VueApexCharts from "vue3-apexcharts";
import VueDatePicker from "@vuepic/vue-datepicker";
const head = createHead();
export default function useImports(app) {
  app.directive("numeric", numeric);
  app.directive("img-loaded", imgLoaded);
  app.directive("maxlength", maxlength);
  app.use(Toast, {
    timeout: 8000,
    pauseOnFocusLoss: false,
    hideProgressBar: false,
    position: "top-center",
    transition: "Vue-Toastification__fade",
  });
  app.component("GenericCard", GenericCard);
  app.component("CountrySelect", CountrySelect);
  app.component("FileUpload", FileUpload);
  app.component("Spinner", Spinner);
  app.component("DashboardHeader", DashboardHeader);
  app.component("v-otp-input", VOtpInput);
  app.component("DashboardTabs", DashboardTabs);
  app.component("EmptyState", EmptyState);
  app.component("ErrorState", ErrorState);
  app.component("HeaderSummary", HeaderSummary);
  app.component("Checkbox", Checkbox);
  app.component("ModalWrapper", ModalWrapper);
  app.component("DobPicker", DobPicker);
  app.component("GenderPicker", GenderPicker);
  app.component("PermissionControl", PermissionControl);
  app.component("ActionConfirmation", ActionConfirmation);
  app.component("DatePeriods", DatePeriods);
  app.component("CustomDatePeriod", CustomDatePeriod);
  app.component("DropdownMenu", DropdownMenu);
  app.component("Pagination", Pagination);
  app.component("VueDatePicker", VueDatePicker);
  app.use(head);
  app.use(VueApexCharts);
}

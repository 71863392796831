const roles = [
  {
    name: "Super Administrator",
    shortName: "Super Admin",
    value: "superAdmin",
    iconPath: require("@/assets/icons/superadmin.svg"),
    colorCode: "#F89412",
  },
  {
    name: "Customer Support",
    shortName: "Support",
    value: "admin",
    iconPath: require("@/assets/icons/admin.svg"),
    colorCode: "#CD44F3",
  },
];
export default roles;

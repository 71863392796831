import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getPaymentRequests({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions/payment-requests?limit=${payload?.limit || 20}&page=${
          payload?.page || 1
        }`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getGetwaySummary({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions/payment-requests/overview`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPaymentRequestsLogs({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions/payment-requests/logs?limit=${
          payload?.limit || 20
        }&page=${payload?.page || 1}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

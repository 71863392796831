<template>
  <div class="row py-2 px-3">
    <!-- Server Time -->
    <div
      class="col-lg-4 d-flex flex-column justify-content-center"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Server Time"
    >
      <div class="mz-text__body--12 mz-text--white mz-text--light">S.Time</div>
      <div class="mz-text__body--12 mz-text--white mz-text--bold">
        {{ formattedServerTime }}
      </div>
    </div>

    <!-- Date -->
    <div class="col-lg-4 d-flex flex-column justify-content-center">
      <div class="mz-text__body--12 mz-text--white mz-text--light">Date</div>
      <div class="mz-text__body--12 mz-text--white mz-text--bold">
        {{ formattedDate }}
      </div>
    </div>

    <!-- Presence Time -->
    <div
      class="col-lg-4 d-flex flex-column justify-content-center text-start"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Presence Time"
    >
      <div class="mz-text__body--12 mz-text--white mz-text--light">P.Time</div>
      <div class="mz-text__body--12 mz-text--white mz-text--bold">
        {{ formattedCurrentTime }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import bootstrap from "bootstrap/dist/js/bootstrap.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export default {
  name: "HeaderActivityCounter",
  data() {
    return {
      serverTime: dayjs().tz("America/New_York").format(),
      currentDate: Date.now(),
      currentTime: Date.now(),
    };
  },
  computed: {
    formattedServerTime() {
      return dayjs(this.serverTime).format("HH:mm");
    },
    formattedDate() {
      return dayjs(this.currentDate).format("DD - MM - YYYY");
    },
    formattedCurrentTime() {
      return dayjs(this.currentTime).format("HH:mm");
    },
  },
  mounted() {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
};
</script>

<style></style>

import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getKyc({ commit, state }, payload) {
    try {
      const query = `page=${payload?.page || 1}&limit=${
        payload?.limit || 30
      }&search=${payload?.search || ""}`;
      const { data } = await axiosService.get(
        `/kyc?${query}&${new URLSearchParams(payload).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getKycSummary({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/kyc/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleKyc({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/kyc/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getKycInsights({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/insights/kyc`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateKycStatus({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/kyc/${payload.id}`, {
        status: payload.status,
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

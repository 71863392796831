import { createApp } from "vue";
export const app = createApp({});
export const eventBus = (app.config.globalProperties.$eventBus = {
  bus: app.provide(
    "eventBus",
    (app.config.globalProperties.$emitter =
      app.config.globalProperties.$emitter || {})
  ),
  emit(event, ...args) {
    this.bus[event]?.forEach((callback) => callback(...args));
  },
  on(event, callback) {
    this.bus[event] = this.bus[event] || [];
    this.bus[event].push(callback);
  },
});

<template>
  <div class="dropdown mz-dropdown me-1">
    <button class="mz-button mz-button--primary mz-button--smaller dropdown-toggle mz-button--plain" type="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      {{ selectedPeriod && selectedPeriod.label }}
      <i class="bi bi-caret-down-fill ms-2"></i>
    </button>
    <ul class="dropdown-menu mz-dropdown-menu dropdown-menu-end">
      <li v-for="(item, index) in periods" :key="index">
        <a class="dropdown-item d-flex align-items-center justify-content-between" href="javascript:void(0)"
          @click="setPeriod(item)">
          {{ item.label }}
          <i class="bi bi-check2" v-if="selectedPeriod && selectedPeriod.value === item.value"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import dayjs from "dayjs";
import periods from "@/models/dateperiods.models";

export default {
  name: "DatePeriods",
  props: {
    isMonthYear: {
      type: Boolean,
      default: false,
    },
    isYear: {
      type: Boolean,
      default: false,
    },
    isRange: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      periods,
      selectedPeriod: null,
      startDate: null,
      endDate: null,
    };
  },
  created() {
    this.setPeriod(this.periods[0]);
  },
  methods: {
    setPeriod(period) {
      // console.log({ period }, 'period')
      if (this.selectedPeriod && this.selectedPeriod.value === period.value)
        return;
      this.selectedPeriod = period;
      this.computeDateRange(period);
      this.$emit("periodChanged", {
        ...this.selectedPeriod,
        startDate: this.startDate,
        endDate: this.endDate,
        selectedPeriod: this.selectedPeriod
      });
    },

    computeDateRange(period) {
      const now = new Date();
      // let startDate;
      this.endDate = now.getTime();

      switch (period.value) {
        case "last24hrs":
          this.startDate = new Date(now);
          this.startDate.setDate(now.getDate() - 1);
          this.startDate = this.startDate.getTime();
          // console.log(this.startDate.getTime());
          break;

        case "lastWeek":
          this.startDate = new Date(now);
          this.startDate.setDate(now.getDate() - 7);
          this.startDate = this.startDate.getTime();
          break;

        case "lastMonth":
          this.startDate = new Date(now);
          this.startDate.setMonth(now.getMonth() - 1);
          this.startDate = this.startDate.getTime();
          break;

        case "lastYear":
          this.startDate = new Date(now);
          this.startDate.setFullYear(now.getFullYear() - 1);
          this.startDate = this.startDate.getTime();
          break;

        case "all":
        default:
          // startDate = undefined;
          this.startDate = "";
          this.endDate = "";
          break;
      }
      /*
      const today = dayjs();
      switch (period.value) {
        case "last24hrs":
          this.startDate = today.subtract(1, "day").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;
        case "lastWeek":
          this.startDate = today.subtract(1, "week").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;
        case "lastMonth":
          this.startDate = today.subtract(1, "month").format("YYYY-MM-DD");
          this.endDate = today.format("YYYY-MM-DD");
          break;
        case "lastYear":
          this.endDate = today.format("YYYY-MM-DD");
          this.startDate = today.subtract(1, "year").format("YYYY-MM-DD");
          break;
        default:
          this.startDate = "";
          this.endDate = "";
      }
          */
    },
    handlePeriodChange() {
      this.computeDateRange(this.selectedPeriod);
    },
  },
};
</script>

<style></style>

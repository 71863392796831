<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { useSeoMeta, useHead } from "unhead";
export default {
  name: "App",
  setup() {
    useHead({
      titleTemplate: "%s | Morizon",
    });
    useSeoMeta({
      title: "Admin",
      description: "Morizon Admin Deck",
      ogDescription: "Morizon Admin Deck",
      ogTitle: "Morizon | Admin",
    });
  },
};
</script>

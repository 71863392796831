export const DashboardRoutes = {
  path: "/desk",
  name: "DashboardLayout",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/DashboardLayout.vue"),
  children: [
    {
      path: "/desk",
      name: "AdminDashboard",
      component: () => import("@/views/dashboard/Index.vue"),
    },
    {
      path: "/desk/referrer-setup",
      name: "ReferralSetup",
      component: () => import("@/views/dashboard/ReferrerSetup.vue"),
    },
    {
      path: "/desk/transaction-oversight",
      name: "TransactionOversight",
      component: () => import("@/views/dashboard/TransactionOversight.vue"),
    },
    {
      path: "/desk/payment-gateway-control",
      name: "PaymentGatewayControl",
      component: () => import("@/views/dashboard/PaymentGatewayControl.vue"),
    },
    {
      path: "/desk/interface-management",
      name: "InterfaceManagement",
      component: () => import("@/views/dashboard/InterfaceManagement.vue"),
    },
    {
      path: "/desk/analysis-and-reporting",
      name: "AnalysisAndReporting",
      component: () => import("@/views/dashboard/AnalysisAndReporting.vue"),
    },
    {
      path: "/desk/aws",
      name: "AWS",
      component: () => import("@/views/dashboard/AWS.vue"),
    },
  ],
};

import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};
const actions = {
  async getAnalytics({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async featureUsageData({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/analytics/features?${new URLSearchParams(payload).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async popularFeatures({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/features/trending`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getUserPainPoints({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/user-pain-points`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async transactionsOverview({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/transactions`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPerformanceData({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/performance`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCompanyGoals({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/company-goals`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createCompanyGoal({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/analytics/company-goals`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removeCompanyGoal({ commit, state }, goalId) {
    try {
      const { data } = await axiosService.delete(
        `/analytics/company-goals/${goalId}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRiskAccessment({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/risk-assessment`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getFraudAlerts({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/fraud-alerts`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getGenderData({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/customer/gender`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCustomerDemographic({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/analytics/customer/demographic?${new URLSearchParams(
          payload
        ).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTransactionInsights({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/transactions/insights`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAdminInsights({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/insights`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCardAnalytics({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/analytics/cards`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getTransactions({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions?limit=${payload?.limit || 20}&page=${
          payload?.page || 1
        }&category=${payload?.category || ""}&startDate=${
          payload.startDate
        }&endDate=${payload.endDate}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getMonthlyTransactions({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions/monthly?${new URLSearchParams(payload).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async totalProfits({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/transactions/tx-profits/`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleTransaction({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/transactions/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async exportTransactions({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/transactions/export?startDate=${payload.startDate}&endDate=${payload.endDate}`,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async toggleHoldState({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/transactions/${payload.id}/hold`,
        {
          isOnHold: payload.isOnHold,
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getTransactionSummary({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/transactions/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

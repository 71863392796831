import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getReferrals({ commit, state }, payload) {
    try {
      const query = `page=${payload?.page || 1}&limit=${
        payload?.limit || 30
      }&search=${payload?.search || ""}&period=${payload?.period || ""}&tier=${
        payload?.tier || ""
      }&${new URLSearchParams(payload).toString()}`;
      const { data } = await axiosService.get(`/referrer?${query}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getReferrerSummary({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/referrer/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleReferrer({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/referrer/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addReferrerAccount({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/referrer/iban`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addReferrer({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/referrer`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async setupRefFees({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/referrer/settings`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getReferrerFees({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/referrer/settings`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getRefLogs({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/referrer/${payload?.user}/logs`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";
import {
  auth,
  admin,
  resources,
  referrer,
  transactions,
  users,
  payment_gateway,
  account,
  analytics,
  kyc,
  notification,
} from "./modules";
const store = createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    resources,
    admin,
    referrer,
    transactions,
    users,
    payment_gateway,
    account,
    analytics,
    kyc,
    notification,
  },
});
export default store;

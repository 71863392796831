import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async addNewAdmin({ commit, state }, credentials) {
    try {
      const { data } = await axiosService.post(
        "/auth/register/admin",
        credentials
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async generatePassphrase({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/passphrase`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAdminSummary({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/overview`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAllAdmins({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin?limit=${payload?.limit || 20}&page=${payload?.page || 1}&role=${payload?.role || ""
        }`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getSingleAdmin({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateAdminInfo({ commit, state }, { id, payload }) {
    try {
      await axiosService.post(`/admin/${id}`, {
        ...payload,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateAdminTwoFa({ commit, state }, { id, payload }) {
    try {
      await axiosService.put(`/2fa/${id}`, {
        ...payload,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async searchAdmin({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/admin/search?limit=${payload?.limit || 20}&page=${payload?.page || 1
        }&role=${payload?.role || ""}`,
        {
          field: payload?.field,
          value: payload?.value,
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async limitAdmin({ commit, state }, payload) {
    try {
      await axiosService.put(`/admin/limit`, {
        user: payload?.user,
        accessLimited: payload?.value,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async removeAdmin({ commit, state }, payload) {
    try {
      await axiosService.delete(`/admin/remove?${new URLSearchParams(payload).toString()}`, {
        user: payload?.user,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async resetAccount({ commit, state }, payload) {
    try {
      await axiosService.post(`/admin/reset-account`, {
        field: payload?.field,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPresenceStats({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/admin/presence-logs`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAdminConversations({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin-conversations?limit=${payload?.limit || 20}&page=${payload?.page || 1
        }`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleConversations({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin-conversations/${payload.admin}?limit=${payload?.limit || 20
        }&page=${payload?.page || 1}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async sendAdminMessage({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/admin-conversations`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getAdminNotice({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/admin/notice`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateAdminNotice({ commit, state }, payload) {
    try {
      await axiosService.put(`/admin/notice`, {
        ...payload,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateAdminRole({ commit, state }, payload) {
    try {
      await axiosService.put(`/admin/permissions`, {
        ...payload,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getActivityLogs({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/admin/logs?limit=${payload?.limit || 20}&page=${payload?.page || 1}&role=${payload?.role || 'admin'}&selectedPeriod=${payload?.selectedPeriod || 'all'}&${new URLSearchParams(payload).toString()}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

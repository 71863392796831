import axiosService from "@/services/axiosService";
import router from "@/router";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllUsers({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/user?limit=${payload?.limit || 20}&page=${payload?.page || 1}`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getSingleUser({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/user/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async searchUser({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(
        `/user/search?limit=${payload?.limit || 20}&page=${payload?.page || 1}`,
        {
          field: payload.field || "",
          value: payload.value || "",
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

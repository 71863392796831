<template>
  <div class="row mz-header__summary-item align-items-center py-2 px-3">
    <div :class="summaryClass" v-for="(item, index) in summary" :key="index">
      <div class="mz-text__body--12 mz-text--white mz-text--light">
        {{ item.title }}
      </div>
      <div
        class="mz-text__body--12 mz-text--white mz-text--bold text-capitalize"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSummary",
  props: {
    summary: {
      type: Array,
      default: () => [],
    },
    summaryClass: {
      type: String,
      default: "col-lg-4",
    },
  },
};
</script>

<style>
.mz-header__summary-item {
  min-height: 70px;
  width: 100%;
  background: var(--mz-white-muted-1);
  border-radius: 8px;
}
</style>
